<template>
  <div class="value-market-item mb-4 mt-2">
    <div class="row">
      <div class="col-12 overflow-hidden">
        <div class="value-market-ticker">
          <ul>
            <li>
              <span class="animated-text">Welcome to Cow!!!</span>
              <span class="animated-text">Welcome to Cow!!!</span>
              <span class="animated-text">Welcome to Cow!!!</span>
              <span class="animated-text">Welcome to Cow!!!</span>
              <span class="animated-text">Welcome to Cow!!!</span>
              <span class="animated-text">Welcome to Cow!!!</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBox"
};
</script>

<style scoped>
@keyframes slideText {
  0% {
    transform: translateX(100%); /* Start outside the right of the container */
  }
  100% {
    transform: translateX(-100%); /* End outside the left of the container */
  }
}
.animated-text {
  display: inline-block; /* Ensure the spans are in one line */
  white-space: nowrap; /* Prevent text from wrapping */
  margin-right: 20px; /* Add spacing between spans */
  animation: slideText 2s linear infinite; /* Adjust animation duration for smoother scrolling */
}
.value-market-ticker {
  white-space: nowrap; /* Ensure everything stays on one line */
  overflow: hidden; /* Hide the overflowing text */
}
</style>
