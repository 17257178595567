<template>
  <MessageBox />

  <div>
    <!-- Skeleton Loader -->
    <div v-if="loading" class="row">
      <div class="col-12 mb-3" v-for="n in 6" :key="n">
        <div class="skeleton-loader p-2"></div>
      </div>
    </div>

    <!-- Task Categories -->
    <div v-else v-for="(taskList, category) in tasks" :key="category">
      <h5 class="fw-bold mb-4 category-title three-d-text">
        {{ capitalizeFirstLetter(category) }}
      </h5>

      <div class="task-grid">
        <!-- Task Cards with 1 in 1 Row -->
        <div
          class="task-item card shadow border-0"
          v-for="task in taskList"
          :key="task.id"
        >
          <div
            class="card-body d-flex justify-content-between align-items-center p-3 task-content"
          >
            <!-- Task Info with Icon -->
            <div class="d-flex align-items-center">
              <img
                src="img/icons/bitcoin.png"
                alt="icon"
                class="me-3 task-icon"
                width="40"
                height="40"
              />
              <div>
                <h6 class="mb-0 task-name">{{ task.name }}</h6>
                <small class="task-reward">+{{ task.reward }}</small>
              </div>
            </div>
            <!-- Task Action Button -->
            <button
              v-if="task.status === 'pending'"
              @click="handleTaskCompletion(task.id, task.link, task.status)"
              class="btn btn-task"
            >
              +
            </button>
            <button v-else class="btn btn-completed" disabled>Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from "@/components/HeaderMessageBox.vue";
import axios from "axios";

export default {
  name: "Tasks",
  components: { MessageBox },
  data() {
    return {
      tasks: {}, // Holds task categories and tasks
      loading: true,
      token: null,
    };
  },
  mounted() {
    this.getTasks();
  },
  methods: {
    getTasks() {
      this.token = localStorage.getItem("token");
      const apiTasks = "https://cowterest.online/api/tasks";
      axios
        .get(apiTasks, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "success") {
            this.tasks = data.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleTaskCompletion(taskId, taskLink, taskStatus) {
      if (taskStatus === "complete") return;

      window.open(taskLink, "_blank");

      const apiTaskDone = `https://cowterest.online/api/tasks/${taskId}`;
      axios
        .post(
          apiTaskDone,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        )
        .then(() => this.getTasks());
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style scoped>
/* Gradient Background */
.gradient {
  background: linear-gradient(135deg, #1a1a1a, #ff007f);
}

.background {
  max-height: calc(100vh - 100px); 
  padding-bottom: 100px; 
}
/* Page Title */
.page-title {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  font-size: 26px;
  letter-spacing: 1px;
}

/* Task Category Title */
.category-title {
  color: #ff80bf;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-left: 20px;
  margin-top: 30px; 
}

/* Task Grid - 1 task per row */
.task-grid {
  display: grid;
  grid-template-columns: 1fr; /* One column */
  gap: 10px; 
  margin-bottom: 90px;
  margin-left: 20px;
  margin-right: 20px;
}

/* Skeleton Loader */
.skeleton-loader {
  height: 80px;
  background-color: #2c2c2c;
  border-radius: 8px;
  animation: skeleton-loading 1s infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #3a3a3a;
  }
  100% {
    background-color: #2c2c2c;
  }
}

/* Compact Card Styles */
.task-item {
  border-radius: 15px;
  background: #2b2b2b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.task-item:hover {
  transform: scale(1.02);
}

/* Typography */
.task-name {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  font-size: 16px;
}

.task-content {
  color: #ffffff;
  transition: background 0.3s ease;
}

.task-item:hover .task-content {
  background-color: rgba(255, 0, 127, 0.1); /* Pink hover effect */
}

/* Task Icon */
.task-icon {
  border-radius: 50%;
  padding: 5px;
  background-color: #ff80bf; /* Light pink background */
}

/* Button Styles */
.btn-task {
  background: linear-gradient(135deg, #ff007f, #ff80bf); /* Pink gradient */
  color: #fff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  transition: all 0.3s ease;
}

.btn-task:hover {
  transform: scale(1.05);
}

.btn-completed {
  background-color: #444444;
  color: #cccccc;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 20px;
}

.task-reward {
  color: #4caf50; /* Bright green color */
  font-weight: 700; /* Bold font weight */
  font-size: 16px; /* Slightly larger for visibility */
  margin-top: 5px; /* Add slight spacing */
  font-family: "Roboto", sans-serif;
}

/* Animation */
@keyframes hover-grow {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.three-d-text {
  color: #fffdfe;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 0 #ff80bf, 5px 4px 0 #000000;
  margin-left: 20px;
  margin-top: 30px;
}

</style>
