import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';

// Import your CSS files
import './assets/css/animate.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/font-google.css';
import './assets/css/jquery.jConveyorTicker.min.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/style.css';

// Import your JavaScript files
import './assets/js/bootstrap.bundle.min.js';
import './assets/js/clipboard.min.js';
import './assets/js/telegram-web-app.js';
import 'animate.css';

const app = createApp(App);

app.use(router);
app.mount('#app');

document.addEventListener('DOMContentLoaded', () => {
  if (window.Telegram && window.Telegram.WebApp) {
    const webApp = window.Telegram.WebApp;

    webApp.isVerticalSwipesEnabled = false;

    webApp.ready();
  }
});
