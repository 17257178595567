import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
import Tasks from './pages/Tasks.vue';
import Reward from './pages/Reward.vue';
import Friends from './pages/Friends.vue';

const routes = [
    {path : "/" , name : 'home' ,component: Home},
    {path : "/tasks" , name : 'tasks' ,component: Tasks},
    {path : "/friends" , name : 'friends' ,component: Friends},
    {path : "/reward" , name : 'reward' ,component: Reward},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;