<template>
    <div v-if="showTutorial" class="modal show tutorial-overlay" tabindex="-1" style="display: block;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="text-center modal-title">Welcome to Cow Coin!</h5>
            <button type="button" class="btn-close" @click="skipTutorial"></button>
          </div>
          <div class="modal-body text-center">
            <p class="tutorial-step">{{ steps[tutorialStep] }}</p>
            <div class="mt-3">
              <button v-if="tutorialStep < steps.length - 1" class="btn btn-primary" @click="nextStep">Next</button>
              <button v-else class="btn btn-success" @click="endTutorial">Finish</button>
              <button class="btn btn-secondary" @click="skipTutorial">Skip Tutorial</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  
<script>
export default {
  data() {
    return {
      showTutorial: false,
      tutorialStep: 0,
      steps: [
        "Step 1: Let us show you around.",
        "Step 2: Here’s where you can manage your profile.",
        "Step 3: You’re all set! Enjoy exploring!"
      ]
    };
  },
  mounted() {
    this.checkFirstVisit();
  },
  methods: {
    checkFirstVisit() {
      const tutorial = localStorage.getItem('tutorial');
      if (!tutorial) {
        this.showTutorial = true; 
      }
    },
    nextStep() {
      this.tutorialStep++;
    },
    skipTutorial() {
      this.endTutorial();
    },
    endTutorial() {
      localStorage.setItem('tutorial', 'true');
      this.showTutorial = false; 
    }
  }
};
</script>

  
<style scoped>
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tutorial-step {
  font-size: 1.1rem;
  margin-bottom: 20px;
}
</style>
