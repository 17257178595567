<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-bar">
        <router-link :to="{ name: 'friends' }" class="footer-item">
          <img src="img/menu/friend-request.png" alt="Friends" />
          <span>Friends</span>
        </router-link>

        <router-link :to="{ name: 'home' }" class="footer-item">
          <img src="img/menu/cow.png" alt="Mine" />
          <span>Mine</span>
        </router-link>

        <router-link :to="{ name: 'tasks' }" class="footer-item">
          <img src="img/menu/reward.svg" alt="Tasks" />
          <span>Tasks</span>
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  z-index: 999;
}

.container {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.footer-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 75px;
  background: var(--color1);
  border-top: 1px solid #444658;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 0;
  z-index: 999;
  overflow: hidden;
}

.footer-item {
  text-align: center;
  flex-grow: 1;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  color: var(--color7);
  line-height: 24px;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-item img {
  max-width: 40px;
  height: auto;
}

.footer-item span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: var(--color7);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-item.active {
  color: var(--color2);
}

.btn-main {
  color: var(--color1);
  font-size: 14px;
  padding: 0.5rem 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-item {
    font-size: 10px;
  }

  .footer-item span {
    font-size: 10px;
  }
}
</style>