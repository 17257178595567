<template>
  <div class="service-item pt-6">
    <div class="row g-3">
      <!-- first item -->
      <div class="col-4">
        <div class="service-card-disable text-center typography-3d" style="font-size: 19px;font-weight: bold;">
          <div class="card">
            <a href="#">
              <svg
                version="1.1"
                width="25"
                height="25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                style="enable-background: new 0 0 512 512"
                xml:space="preserve"
              >
                <path
                  d="M312.942,424.774L276,461.28V276c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v183.303l-34.942-34.529
				c-7.857-7.764-20.52-7.688-28.284,0.168c-7.764,7.857-7.688,20.52,0.168,28.284l41.589,41.098
				C225.878,505.724,240.955,512,257,512s31.122-6.276,42.469-17.676l41.589-41.098c7.856-7.764,7.932-20.427,0.168-28.284
				C333.461,417.085,320.799,417.009,312.942,424.774z"
                />
                <path
                  d="M492,276c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40h-96c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h96
				c44.112,0,80-35.888,80-80C512,284.954,503.046,276,492,276z"
                />
                <path
                  d="M311.172,253.815c3.35,2.157,7.101,3.188,10.809,3.187c6.573,0,13.011-3.237,16.834-9.174
				C350.712,229.354,357,208.277,357,186.878c0-59.551-45.309-108-101-108c-55.691,0-101,48.449-101,108
				c0,22.906,5.798,43.438,17.233,61.024c6.021,9.26,18.41,11.886,27.669,5.865c9.26-6.021,11.886-18.409,5.865-27.669
				C198.623,215.109,195,201.915,195,186.878c0-37.495,27.364-68,61-68s61,30.505,61,68c0,13.702-4.085,27.29-11.815,39.293
				C299.205,235.459,301.885,247.835,311.172,253.815z"
                />
                <circle cx="415" cy="187" r="20" />
                <circle cx="97" cy="187" r="20" />
                <path
                  d="M432,0H80C35.888,0,0,35.888,0,80v216c0,44.112,35.888,80,80,80h96c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80
				c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h352c22.056,0,40,17.944,40,40v116c0,11.046,8.954,20,20,20
				c11.046,0,20-8.954,20-20V80C512,35.888,476.112,0,432,0z"
                />
              </svg>
            </a>
          </div>
          <span class="mb-0" style="color: #ffffff;">Airdrop</span>
        </div>
      </div>
      <!-- end first item -->

      <!-- second item -->
      <div class="col-4">
        <!-- <div class="service-card text-center"> -->
        <TonConnectButton />
        <!-- </div> -->
      </div>
      <!-- end second item -->

      <!-- latest item -->
      <div class="col-4">
        <div class="service-card text-center typography-3d" style="font-size: 19px;font-weight: bold;">
          <div class="card">
            <router-link :to="{ name: 'reward' }">
              <a href="#">
                <svg
                  height="25"
                  width="35"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 484.646 484.646"
                  xml:space="preserve"
                  fill="#000000"
                >
                </svg>
              </a>
            </router-link>
          </div>
          <p class="mb-0" style="color: #ffffff;font-size: 19px;font-weight: bold;">Cow</p>
        </div>
      </div>
      <!-- end latest item -->
    </div>
  </div>
</template>

<script>
import { TonConnectButton } from "@townsquarelabs/ui-vue";

export default {
  name: "ServiceItems",
  components: {
    TonConnectButton,
  },
};
</script>

<style scoped>
.typography-3d {
  text-shadow: 2px 2px 5px #ff00b3, 2px 2px 10px #ff69b4;
}
</style>