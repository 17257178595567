<template>
  <Alert ref="alertComponent" />

  <TonConnectUIProvider :options="options">
    <Header />
    <Footer />
    <Tutorial />
    <router-view />
  </TonConnectUIProvider>
</template>

<script>
import Header from './components/Header.vue';
import Tutorial from './components/Tutorial';
import Footer from './components/Footer.vue';
import Alert from './components/Alert.vue';
import { TonConnectUIProvider } from '@townsquarelabs/ui-vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Tutorial,
    TonConnectUIProvider,
    Alert,
  },
  setup() {
    const options = {
      manifestUrl: "https://cowterest.online/tonconnect-manifest.json",
    };
    return { options };
  },
  mounted() {
    // Initialize Telegram WebApp
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready(); // Ensures the WebApp is ready

      // Manually handle scrolling and fix swipe issues if necessary
      this.fixScrollingIssue();
    }
  },
  provide() {
    return {
      showGlobalAlert: this.showGlobalAlert,
    };
  },
  methods: {
    fixScrollingIssue() {
      // This method will fix the scrolling issue by preventing unwanted scroll behavior
      window.addEventListener('touchmove', (e) => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop <= 0) {
          e.preventDefault();
          window.scrollTo(0, 1); // Prevents scrolling back to the top
        }
      }, { passive: false });
    },
    showGlobalAlert(message, theme) {
      this.$refs.alertComponent.showAlert(message, theme);
    },
  },
};
</script>

<style scoped>
/* Add relevant styles here */
</style>
