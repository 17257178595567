<template>
  <HeaderMessageBox />
  <main class="main-content">
    <div class="container">
      <!--  balance -->
      <BalanceCard :formattedBalance="formattedBalance" />

      <!-- Service items -->
      <ServiceItems />

      <!-- Cow animation -->
      <CowAnimation
        :profit="profit"
        :remainingTime="remainingTime"
        :claim="claim"
      />
    </div>
  </main>
  <router-view></router-view>
  <Tutorial />
</template>

<script>
import { inject } from "vue";
import HeaderMessageBox from "@/components/HeaderMessageBox.vue";
import BalanceCard from "@/components/BalanceCard.vue";
import ServiceItems from "@/components/ServiceItems.vue";
import CowAnimation from "@/components/CowAnimation.vue";
import axios from "axios";
import Tutorial from "@/components/Tutorial.vue";

export default {
  name: "Home",
  components: {
    HeaderMessageBox,
    BalanceCard,
    ServiceItems,
    CowAnimation,
    Tutorial,
  },
  data() {
    return {
      token: null,
      balance: 0,
      profit: 0,
      remainingTime: "",
      timer: null,
      isPremium: false,
      isClaimDisabled: false,
      claimed_at: null,
      userName: null,
      firstName: null,
      lastName: null,
    };
  },
  setup() {
    const showGlobalAlert = inject("showGlobalAlert");
    return { showGlobalAlert };
  },
  watch: {
    claimed_at() {
      this.resetTimer();
    },
  },
  mounted() {
    this.initializeData();
  },
  beforeUnmount() {
    this.clearTimer();
  },
  methods: {
    initializeData() {
      const apiInit = "https://cowterest.online/api/init";
      axios
        .post(apiInit, {}, this.getAuthHeaders())
        .then(this.handleInitResponse)
        .catch(this.reloadPage);
    },
    handleInitResponse(response) {
      const data = response.data;
      if (data.status === "success") {
        this.storeUserData(data.data);
        this.startTimer();
      } else {
        this.reloadPage();
      }
    },
    storeUserData(data) {
      localStorage.setItem("token", data.token);
      this.token = data.token;
      this.userName = data.username;
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.isPremium = data.is_premium;
      this.balance = data.wallet.balance;
      this.profit = data.wallet.profit;
      this.claimed_at = data.wallet.claimed_at;
    },
    claim() {
      const apiClaim = "https://cowterest.online/api/claim";
      if (!this.token) return this.reloadPage();

      axios
        .post(apiClaim, {}, this.getAuthHeaders())
        .then(this.handleClaimResponse)
        .catch(this.handleClaimError);
    },
    handleClaimResponse(response) {
      const data = response.data;
      if (data.status === "success") {
        this.balance = data.data.balance;
        this.claimed_at = data.data.claimed_at; // Triggers watcher to update the timer
        this.showGlobalAlert("Claim was successful!", "success");
      } else {
        this.showGlobalAlert("Claim failed. Try again!", "error");
        this.reloadPage();
      }
    },
    handleClaimError() {
      this.showGlobalAlert("Error occurred while claiming.", "error");
      this.reloadPage();
    },
    startTimer() {
      this.clearTimer();
      this.timer = setInterval(this.updateClaimStatus, 1000);
    },
    resetTimer() {
      this.clearTimer();
      this.startTimer();
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    updateClaimStatus() {
      if (!this.token || !this.balance || !this.profit) return;

      const claimedAt = new Date(`${this.claimed_at}Z`);
      const now = new Date();
      const accessTime = new Date(claimedAt.getTime() + 60 * 1000); // 1 minute access time
      const timeLeft = accessTime - now;

      if (timeLeft <= 0) {
        this.remainingTime = "Claim yours!";
        this.isClaimDisabled = false;
      } else {
        const minutes = Math.floor(timeLeft / (1000 * 60));
        const seconds = Math.floor((timeLeft / 1000) % 60);
        this.remainingTime = `${minutes}m ${seconds}s`;
        this.isClaimDisabled = true;
      }
    },
    reloadPage() {
      setTimeout(() => location.reload(), 5000);
    },
    getAuthHeaders() {
      return {
        headers: {
          initData:
            "query_id=AAFYBnEzAAAAAFgGcTMUyqf7&user=%7B%22id%22%3A863045208%2C%22first_name%22%3A%22Mohammad%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22imhmwd%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728210504&hash=5becdad29ef4bb1831116cfd5055d72ca0c6556f200345631f014663c033d741",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      };
    },
  },
  computed: {
    formattedBalance() {
      return this.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
