<template>
  <div class="page-wrapper">
    <!-- Cow Animation Section -->
    <div class="cow-main mb-5">
      <div class="container-cow">
        <div class="cow-body">
          <div class="clip-wrapper">
            <div class="cow-udder"></div>
            <div class="arms right-arm"></div>
            <div class="arms left-arm"></div>

            <div class="spot-1"></div>
            <div class="spot-2"></div>
            <div class="spot-3"></div>
            <div class="spot-4"></div>
            <div class="spot-5"></div>
            <div class="spot-6"></div>
          </div>
          <div class="eyes eye-1"></div>
          <div class="eyes eye-2"></div>

          <div class="smile-1"></div>
          <div class="smile-2"></div>

          <div class="ears left-ear"></div>
          <div class="ears right-ear"></div>

          <div class="horns left-horn"></div>
          <div class="horns right-horn"></div>

          <div class="muzzle">
            <div class="nostril-1"></div>
            <div class="nostril-2"></div>
          </div>

          <div class="feet left-foot"></div>
          <div class="feet right-foot"></div>

          <div class="teats teat-1"></div>
          <div class="teats teat-2"></div>
          <div class="teats teat-3"></div>
          <div class="sound-hole"></div>

          <div class="wing-front-1"></div>
          <div class="wing-front-2"></div>

          <div class="wing-front-3"></div>
          <div class="wing-back-1"></div>
          <div class="wing-back-2"></div>
        </div>
      </div>
    </div>

    <!-- Crypto Card Section -->
    <div class="crypto-card-section mt-5">
      <div class="row">
        <div class="col-12">
          <div class="crypto-card overflow-hidden">
            <div class="card-body p-0">
              <div class="mb-0 p-3 row d-flex">
                <div class="col-4 d-flex align-items-center justify-content-start">
                  <h5 class="mr-2">
                    {{ profit }}
                  </h5>
                  <h6>L/H</h6>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center">
                  <a
                    @click.prevent="
                      remainingTime == 'Claim yours!' ? claim() : null
                    "
                    href="#"
                    class="btn btn-lg btn-light"
                    :class="{
                      disabled: remainingTime != 'Claim yours!',
                    }"
                  >
                    Claim
                  </a>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-end">
                  <p class="mr-4 mb-0 font-weight-bold" style="font-size: 18px">
                    {{ remainingTime }}
                  </p>
                  <img src="img/menu/timer.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CowAnimation",
  props: {
    profit: {
      type: Number,
      required: true,
    },
    remainingTime: {
      type: String,
      required: true,
    },
    claim: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
.cow-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crypto-card-section {
  margin-bottom: 100px; /* Add some space to ensure visibility of content */
}
</style>
