<template>
  <!-- Header Section -->
  <HeaderMessageBox ref="HeaderMessageBox" />

  <div v-if="loading">
    <!-- Skeleton Loader for Friends Count and Referral Link Section -->
    <div class="card p-4 mb-4 text-center">
      <div class="skeleton skeleton-friends-count mb-3"></div>
      <div class="skeleton skeleton-referral-link mb-3"></div>
      <div class="skeleton skeleton-button"></div>
    </div>

    <!-- Skeleton Loader for Friends List Section -->
    <div class="card p-4 mb-4">
      <h4 class="card-title text-center">Friends List</h4>
      <ul class="list-group list-group-flush">
        <li
          v-for="n in 5"
          :key="n"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <div class="d-flex align-items-center">
            <div class="skeleton skeleton-avatar me-3"></div>
            <div class="skeleton skeleton-friend-name"></div>
          </div>
          <div class="skeleton skeleton-friend-reward"></div>
        </li>
      </ul>
    </div>
  </div>

  <div v-else>
    <div class="background">
      <!-- Friends Count and Referral Link Section -->
      <div class="card p-4 mb-4 text-center animate__animated animate__fadeInDown">
        <div class="d-flex justify-content-center align-items-center mb-3">
          <h2 class="ms-3 mb-0">Your Friends: {{ friendsCount }}</h2>
        </div>

        <!-- Referral Link Input Group with Custom Copy Button -->
        <div class="input-group my-3">
          <input
            type="text"
            class="form-control"
            :value="referralLink"
            readonly
          />
          <button class="btn btn-pink clipboard-icon animate__animated animate__pulse" type="button">
            COPY
          </button>
        </div>

        <!-- Invite Friend Button with Telegram Sharing -->
        <a
          :href="telegramShareLink"
          target="_blank"
          class="btn btn-telegram animate__animated animate__pulse"
        >
          Invite Friend via Telegram
        </a>
      </div>

      <!-- Friends List Section -->
      <section class="friends-list-section">
        <div class="friend-cards">
          <div
            v-for="(friend, index) in friends"
            :key="index"
            class="friend-card animate__animated animate__fadeInUp"
          >
            <div class="friend-info">
              <img
                src="img/menu/friends-list.svg"
                class="friend-avatar"
                alt="Friend Avatar"
              />
              <div class="friend-details">
                <h5 class="friend-name">
                  {{ friend.first_name }} {{ friend.last_name || "" }}
                </h5>
                <span class="reward-amount">+50,000 Milk</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { inject } from "vue"; // Import inject for global access
import HeaderMessageBox from "@/components/HeaderMessageBox.vue";
import axios from "axios";
import ClipboardJS from "clipboard";

export default {
  name: "Friends",
  components: {
    HeaderMessageBox,
  },
  data() {
    return {
      friendsCount: 0,
      referralLink: "",
      friends: [],
      token: null,
      loading: true, // Loader state for skeleton
    };
  },
  setup() {
    const showGlobalAlert = inject("showGlobalAlert"); // Inject the global alert function
    return { showGlobalAlert };
  },
  mounted() {
    this.getFriends();
    this.initClipboard();
  },
  computed: {
    telegramShareLink() {
      // Generate the Telegram share link dynamically based on referralLink
      return `https://t.me/share/url?url=${encodeURIComponent(
        this.referralLink
      )}&text=Join me on Cowterest!`;
    },
  },
  methods: {
    getFriends() {
      this.token = localStorage.getItem("token");
      const apiFriends = "https://cowterest.online/api/friends";
      axios
        .get(apiFriends, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          if (data.status === "success") {
            this.friendsCount = data.data.friends_count;
            this.referralLink = `https://t.me/cowterestbot?ref=${data.data.referral_link}`;
            this.friends = data.data.friends;
            this.loading = false; // Hide skeleton once data is fetched
          }
        })
        .catch((error) => {
          console.error("Error fetching friends:", error);
        });
    },
    initClipboard() {
      const clipboard = new ClipboardJS(".clipboard-icon", {
        text: () => this.referralLink, // Return the text to be copied
      });

      clipboard.on("success", () => {
        this.showGlobalAlert("Referral link copied to clipboard!", "success"); // Show success alert
      });

      clipboard.on("error", () => {
        this.showGlobalAlert("Failed to copy the referral link!", "error"); // Show error alert
      });
    },
  },
};
</script>

<style scoped>
/* Skeleton Loader Styles */
.skeleton {
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton-friends-count {
  height: 30px;
  width: 200px;
  margin: 0 auto;
}

.skeleton-referral-link {
  height: 40px;
  width: 100%;
}

.skeleton-button {
  height: 45px;
  width: 80%;
  margin: 0 auto;
}

.skeleton-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.skeleton-friend-name {
  height: 20px;
  width: 150px;
}

.skeleton-friend-reward {
  height: 20px;
  width: 80px;
}

/* Slide-in animation for cards */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* General Icon Styles */
.icon-friend {
  width: 50px;
  height: 50px;
}
.icon-friend-list {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/* Card Styling */
.card {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

/* Friend Card Styles */
.friend-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  background: linear-gradient(135deg, #775050, #ff00aa);
  animation: slideIn 0.5s ease;
}

.badge {
  font-size: 14px;
}

/* Invite Friend Button - Pink Gradient */
.btn-telegram {
  background-image: linear-gradient(
    to right,
    #f953c6,
    #b91d73
  ); /* Pink gradient */
  color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 30px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
}

.btn-telegram:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 20px rgba(185, 29, 115, 0.4); /* Soft pink shadow */
}

/* Copy Button - Light Pink Background */
.btn-pink {
  background-color: #f8a5c2; /* Light pink */
  color: #ffffff;
  border: none;
  font-weight: 500;
  border-radius: 0 15px 15px 0;
  transition: background-color 0.3s ease;
}

.btn-pink:hover {
  background-color: #ff6b81; /* Darker pink on hover */
}

/* Miscellaneous */
.list-group-item {
  border: none;
  padding: 15px 20px;
}
.list-group-item:hover {
  background: linear-gradient(135deg, #1a1a1a, #ff007f);
}

.input-group .form-control {
  border-right: 0;
  border-radius: 15px 0 0 15px;
}

.input-group .btn {
  border-radius: 0 15px 15px 0;
}

/* Friends List Section */
.friends-list-section {
  margin-top: 30px;
}

.friend-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.friend-card:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.friend-info {
  display: flex;
  align-items: center;
}

.friend-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.friend-details {
  display: flex;
  flex-direction: column;
}

.friend-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  font-weight: bold;
  color: #e0e0e0;
}

.reward-amount {
  font-size: 14px;
  color: #28a745;
  font-weight: 500;
  font-weight: bold;
}

.background {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 100px; /* Prevent content from being hidden under the footer */
}
</style>