<template>
  <div class="item-card">
    <div class="fw-bolder">
      <div class="card-content bg-cow text-center">
        <div class="logo-card img-fluid mx-auto">
        </div>
        <img src="img/menu/bottle.png" alt="bottle" width="50px" height="30px"/>
        <h2 class="text-light balance-text" style="font-size: 29px; font-weight: bold;">{{ formattedBalance }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BalanceCard",
  props: {
    formattedBalance: {
      type: String,
      required: true
    }
  }
}
</script>
