<template>
    <div v-if="visible" :class="alertClass" class="alert" ref="alertBox">
      <span>{{ localMessage }}</span>
    </div>
  </template>
  
  <script>
  export default {
    name: "Alert",
    props: {
      message: String,
      theme: String,
    },
    data() {
      return {
        visible: false,
        localMessage: this.message, // Use local data to hold message
        localTheme: this.theme, // Use local data to hold theme
      };
    },
    computed: {
      alertClass() {
        return this.localTheme === "success" ? "alert-success" : "alert-error";
      },
    },
    methods: {
      showAlert(message, theme) {
        this.localMessage = message;
        this.localTheme = theme;
        this.visible = true;
  
        // Scroll the alert component into view when it appears
        this.$nextTick(() => {
          this.$refs.alertBox.scrollIntoView({
            behavior: "smooth", // Enables smooth scrolling
            block: "center", // Center the alert in the viewport
          });
        });
  
        // Hide the alert after 3 seconds
        setTimeout(() => {
          this.visible = false;
        }, 3000);
      },
    },
  };
  </script>
  
  <style scoped>
  .alert {
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    color: white;
  }
  .alert-success {
    background-color: #4CAF50;
  }
  .alert-error {
    background-color: #f44336;
  }
  </style>
  